<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <advanced-table
        :tableColumns="tableColumns"
        dataAction="devices/GET_IFRAMES"
        :dataURLParams="{ type: $route.params.type }"
        :createRoute="{ name: 'iframe-create', params: $route.params.type }"
        ref="iframesTable"
        :key="count"
        ><template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'actions' ? '50px' : '400px' }"
          />
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :to="{
                name: 'iframe-create',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('edit') }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteIframe(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('delete') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template></advanced-table
      >
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BDropdown,
  BDropdownItem,
  BCard,
  BFormCheckbox,
} from 'bootstrap-vue';
import AdvancedTable from '@/views/components/AdvancedTable.vue';
import i18n from '@/libs/i18n';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BCard,
    BFormCheckbox,
    AdvancedTable,
  },
  data() {
    return {
      tableColumns: [
        { key: 'name', label: i18n.tc('iframe.name'), sortable: true },
        { key: 'actions', label: i18n.tc('actions') },
      ],
      count: 0,
    };
  },
  methods: {
    deleteIframe(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('confirm_deletation_description'), {
          title: this.$t('confirm_deletation'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('yes'),
          cancelTitle: this.$t('no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            const res = await this.$store.dispatch('devices/DELETE_IFRAME', id);
            if (res) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('deleted'),
                  icon: 'Trash2Icon',
                  variant: 'danger',
                  text: this.$t('iframe.iframe_deleted'),
                },
              });
              this.count++;
            }
          }
        });
    },
  },
  watch: {
    '$route.params.type': function(id) {
      setTimeout(() => {
        this.$refs.iframesTable.refresh();
      }, 50);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
